<template>
  <section class="mt-0 mt-md-5">
    <b-overlay :show="loading">
      <div>
        <b-alert
            variant="danger"
            :show="loanData === undefined && !loading"
        >
          <h4 class="alert-heading">
            Error fetching loan details
          </h4>
          <div class="alert-body">
            <b-link
                class="alert-link"
                :to="{ name: 'client-loans'}"
            >
              Loan List
            </b-link>
          </div>
        </b-alert>
      </div>
      
      <div v-if="loanData">
        <div>
          <!-- loan status-->
          <div class="">
            <b-badge
                pill
                :variant="`light-${resolveStatusVariant(getValueFromSource(loanData, 'status'))}`"
                class="text-capitalize mb-1 py-50 px-2"
            >
              {{ getUserStatusText(getValueFromSource(loanData, 'status')) }}
            </b-badge>
          </div>

          <!-- show warning if loan is processing-->
          <div>
            <div v-if="isPendingLoan">
              <div>
                <p
                    v-if="getValueFromSource(loanData, 'status') !== 'pending-product-delivery'"
                >
                  Please wait: your loan request is being processed. You will be updated when it has been processed.
                  <br />
                  Thank you for your patience
                </p>
                <div v-else>
                  This loan is pending product delivery.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-row>
            <!--left hand side of page-->
            <b-col class="" cols="12" xl="8" md="8">

              <div>
                <div class="border-primary card-body rounded-lg">
                  <div>
                    <loan-info-card :loan-data="loanData" :repayments="repayments" />
                  </div>

                  <div class="mt-2">
                    <div>
                      <b-button
                          v-if="userCanCancelLoanRequest"
                          variant="danger"
                          @click="onCancelLoanRequest"
                      >
                        Cancel Request
                      </b-button>
                    </div>
                    <div>
                      <b-button
                          v-if="userCanPayOffLoan"
                          variant="primary"
                          @click="$router.push({ name: 'client-pay-off-loan', params: { loan_id: loanData._id } })"
                      >
                        Make Payment
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div>

                <div v-if="getValueFromSource(loanData, 'attachments', []).length > 0">
                  <h5
                      class="mb-1 mt-2"
                  >
                    Attachments
                  </h5>

                  <loan-attachments :attachments="getValueFromSource(loanData, 'attachments', [])" />
                </div>
              </div>

              <div>
                <div v-if="['declined'].includes(loanData.status) && adminFlaggedReason.length > 0">
                  <h5
                      class="mb-1 mt-2"
                      :class="{
                  'text-danger': loanData.status === 'declined',
                  'text-warning': loanData.status === 'flagged'
                }"
                  >
                    <feather-icon
                        icon="HelpCircleIcon"
                        size="18"
                    />
                    Declined Reason
                  </h5>

                  <declined-or-flagged-reasons :flagged-reasons="adminFlaggedReason"
                                               :variant="loanData.status === 'declined' ? 'danger' : 'warning'"
                  />
                </div>

              </div>

              <div>
                <div class="mt-3">
                  <loan-repayments-table v-if="isProcessedLoan" :repayments="repayments" />
                </div>
              </div>

            </b-col>


            <!--Right hand side of page -->
            <b-col>
              <!-- loan summary-->
              <loan-action-card :loan-data="loanData" :repayments="repayments" @refetchLoanData="fetchLoanDetails" />

              <!-- Button to loan statement-->
              <div v-if="isProcessedLoan">
                <div class="mt-2">
                  <b-link
                      :to="{ name : 'client-statements-single', params: { loan_id: getValueFromSource(loanData, 'id') }}"
                  >
                    <b-button variant="primary">
                      Loan Statement
                    </b-button>
                  </b-link>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay, BRow, BCol, BAlert, BLink, BButton, BBadge
} from 'bootstrap-vue'

import { get } from "lodash";

import LoanAttachments from "@/@core/components/shared/loans/LoanAttachments.vue";
import LoanRepaymentsTable from '@core/components/shared/loans/LoanRepaymentsTable.vue';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DeclinedOrFlaggedReasons from "@/@core/components/shared/loans/DeclinedOrFlaggedReasons.vue"

import LoanInfoCard from './LoanInfoCard.vue';
import LoanActionCard from './LoanActionCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BAlert,
    BBadge,
    BButton,
    BOverlay,

    LoanInfoCard,
    LoanActionCard,
    LoanRepaymentsTable,
    LoanAttachments,
    DeclinedOrFlaggedReasons
  },
  data(){
    return {
      loading: false,
      loanData: null,
      repayments: [],
    }
  },
  computed: {
    adminFlaggedReason() {
      return this.getValueFromSource(this.loanData, 'system_flagged_reasons', []).filter(flg_reason => flg_reason.error === 'ADMIN_DECLINED')
    },
    isProcessedLoan() {
      return ['disbursed', 'paid', 'loss', 'overdue'].includes(this.getValueFromSource(this.loanData, 'status'))
    },
    isPendingLoan() {
      return this.getLoanStatusTextForClient(this.getValueFromSource(this.loanData, 'status')) === 'pending';
    },
    userCanCancelLoanRequest() {
      const statusAllowedForCancelling = ['flagged', 'pending', 'admin-approve'];
      return statusAllowedForCancelling.includes(this.getValueFromSource(this.loanData, 'status'));
    },
    userCanPayOffLoan() {
      return ["disbursed", "overdue", "loss"].includes(this.getValueFromSource(this.loanData, 'status'));
    },
  },
  created(){
    this.fetchLoanDetails();
  },
  methods: {
    async fetchLoanDetails(){
      try {
        this.loading = true

        const { loan_id } = this.$route.params;
        const response = await this.useJwt().clientService.fetchLoanRecord(loan_id);
        const { loan, repayments } = get(response, "data.data");

        this.loanData = loan;
        this.repayments = repayments;
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });

        this.loanData = undefined
      } finally {
        this.loading = false
      }
    },
    async onCancelLoanRequest(){
      try {
        const { _id: loan_id } = this.loanData

        const result = await this.$swal({
          title: 'Confirm Cancellation?',
          text: "Are you sure you want to cancel this loan request? This action cannot be reversed.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!result.value) {
          return;
        }


        const response = await this.useJwt().clientService.cancelLoanRequest(loan_id);
        this.loanData = get(response, "data.data");
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style>

</style>
