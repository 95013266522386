<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h3 class="text-primary bg-transparent">
        Repayments
      </h3> 

      <b-button
        v-if="isCurrentUserAdmin"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        :disabled="!canReverseDeclinedLoan"
        @click="onRefundDeduction"
      >
        <feather-icon
          icon="CornerUpLeftIcon"
          class="mr-50"
        />
        <span class="align-middle">Refund Deduction</span>
      </b-button>
    </div>

    <b-card no-body>
      <b-table
        ref="refRepaymentsTable"
        :items="repayments"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No data found"
        class="position-relative"
        >
          <template #head(invoiceStatus)>
            <feather-icon
              icon="TrendingUpIcon"
              class="mx-auto"
            />
          </template>

          <!-- Column: Balance -->
          <template #cell(paid_amount)="data">
            <template>
              <b-badge
                pill
                variant="light-success"
              >
                {{ data.value }}
              </b-badge>
            </template>
          </template>
        </b-table>
    </b-card>
  </div>
</template>

<script>
import { formatDate, formatMoney } from "@/@core/utils/utils"
import {
  BCard,
  BRow,
  BCol,
  BAlert,
  BBadge,
  BLink,
  BTable,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BButton
  },
  props: {
    repayments: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      tableColumns: [
        { key: 'submitted_date', sortable: false, formatter: val => `${formatDate(val)}`, label: 'Date', class: 'w-25' },
        { key: 'paid_amount', sortable: false, label: 'Amount', formatter: val => `¢${formatMoney(val)}`, },
        // { key: 'loan_id', label: 'Loan ID', sortable: false },
      ]
    }
  },
  computed: {
    adminPermissions(){
      return this.getValueFromSource(this.$store.getters[`auth/userData`], 'permissions', {});
    },
    canReverseDeclinedLoan() {
      return this.getValueFromSource(this.adminPermissions, 'loan_application.can_refund_deduction', false)
    },
  },
  methods: {
    onRefundDeduction() {
      this.$emit("refund")
    }
  }
}
</script>
