<template>
<b-overlay :show="loading">
  <div>
    <b-card
      class="bg-secondary"
    >
      <div>
        <h3 class="mt-1">
          Summary
        </h3>
      </div>
      <div class="text-white">
        <hr>

        <div class="d-flex justify-space-between flex-wrap align-items-center">
          <div class=" flex-grow-1">
            <p class="">
              Request Date
            </p>
          </div>
          <div class="text-capitalize text-right">
            {{ formatDate(getValueFromSource(loanData, 'created')) }}
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap align-items-center">
          <div class=" flex-grow-1">
            <p class="">
              Loan Term
            </p>
          </div>
          <div class="text-capitalize text-right">
            {{ getValueFromSource(loanData, 'loan_term.title') }}
          </div>
        </div>
        
        <div class="d-flex justify-space-between flex-wrap align-items-center">
          <div class=" flex-grow-1">
            <p class="">
              Processing Fee
            </p>
          </div>
          <div class="text-capitalize text-right">
            {{ loanData.processing_fee }}%
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap align-items-center">
          <div class="w-50 flex-grow-1">
            <p class="">
              Payout
            </p>
          </div>
          <div class="text-capitalize text-right">
            Gh¢ {{ formatMoney(loanData.payout) }}
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap align-items-center">
          <div class="w-50 flex-grow-1">
            <p class="">
              Interest Rate
            </p>
          </div>
          <div class="text-capitalize text-right">
            {{ loanData.admin_interest_rate }}%
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap">
          <div class="w-50 flex-grow-1">
            <p class="">
              Interest
            </p>
          </div>
          <div class="text-capitalize text-right">
            Gh¢ {{ formatMoney(loanData.interest) }}
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap ">
          <div class="w-50 flex-grow-1">
            <p class="">
              Monthly Payment
            </p>
          </div>
          <div class="text-capitalize text-right">
            Gh¢ {{ formatMoney(monthlyPayment) }}
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap">
          <div class="w-50 flex-grow-1">
            <p class="">
              Penal Charge
            </p>
          </div>
          <div class="text-capitalize text-right">
            Gh¢ {{ formatMoney(penalCharges) }}
          </div>
        </div>

        <div class="d-flex justify-space-between flex-wrap">
          <div class="w-50 flex-grow-1">
            <h2 class="text-white">
              Total
            </h2>
          </div>
          <div class="text-capitalize text-right">
            <h2 class="text-white">
              Gh¢ {{ formatMoney(loanData.total_loan_cost + penalCharges) }}
            </h2>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'


export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BOverlay
  },
  props: {
    loanData: {
      type: Object,
      required: true,
    },
    repayments: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data(){
    return {
      loading: false
    }
  },
  computed: {
    penalCharges() {
      return this.getValueFromSource(this.loanData, 'penal_charges', []).reduce((accumulator, penal_charge) => accumulator + penal_charge.charge, 0);
    },
    userCanCancelLoanRequest() {
      const statusAllowedForCancelling = ['flagged', 'pending', 'admin-approve'];
      return statusAllowedForCancelling.includes(this.getValueFromSource(this.loanData, 'status'));
    },
    userCanPayOffLoan() {
      return ["disbursed", "overdue", "loss"].includes(this.getValueFromSource(this.loanData, 'status')); 
    },
    repaidAmount() {
      return this.repayments.reduce((accumulator, payment) => accumulator + payment.paid_amount, 0)
    },
    remainingBalance() {
      const total_loan_cost = this.getValueFromSource(this.loanData, "total_loan_cost", 0);
      return (total_loan_cost + this.penalCharges) - this.repaidAmount;
    },
    monthlyPayment() {
      const status = this.getValueFromSource(this.loanData, 'status');
      const monthly_payment = this.getValueFromSource(this.loanData, 'monthly_payment', 0);
      return ["overdue", "loss"].includes(status) ? this.remainingBalance : monthly_payment;
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
