<template>
  <section>
      <div>
        <div class="d-md-flex align-items-center">
          <div class="mr-3">
            <div>
              <p class="m-0 p-0">
                Original Loan Cost
              </p>
            </div>
            <div>
              <h2>
                Gh¢ {{ formatMoney(getValueFromSource(loanData, 'total_loan_cost', 0)) }}
              </h2>
            </div>

          </div>
          <div class="mr-3">
            <div>
              <p class="m-0 p-0">
                Monthly Payment
              </p>
            </div>
            <div>
              <h2>
                Gh¢ {{ formatMoney(monthlyPayment) }}
              </h2>
            </div>

          </div>
          <div v-if="loanHasBeenPaidOut" class="mr-3">
            <div>
              <p class="m-0 p-0">
                Total Paid
              </p>
            </div>
            <div>
              <h2>
                Gh¢ {{ formatMoney(repaidAmount) }}
              </h2>
            </div>

          </div>
          <div v-if="loanHasBeenPaidOut" class="mr-3">
            <div>
              <p class="m-0 p-0">
                Remaining Balance
              </p>
            </div>
            <div>
              <h2>
                ¢{{ formatMoney(remainingBalance) }}
              </h2>
            </div>

          </div>
        </div>
      </div>
  </section>
</template>

<script>

import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    loanData: {
      type: Object,
      required: true,
    },
    repayments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    repaidAmount() {
      return this.repayments.reduce((accumulator, payment) => accumulator + payment.paid_amount, 0)
    },
    remainingBalance() {
      const total_loan_cost = this.getValueFromSource(this.loanData, "total_loan_cost", 0);
      const total_penal_charge = this.getValueFromSource(this.loanData, 'penal_charges', []).reduce((accumulator, penal_charge) => accumulator + penal_charge.charge, 0);
      const total_paid = this.repaidAmount;
      return (total_loan_cost + total_penal_charge) - total_paid;
    },
    loanHasBeenPaidOut() {
      return ["disbursed", "overdue", "approved"].includes(this.getValueFromSource(this.loanData, "status"));
    },
    monthlyPayment() {
      const status = this.getValueFromSource(this.loanData, 'status');
      const monthly_payment = this.getValueFromSource(this.loanData, 'monthly_payment', 0);
      return ["overdue", "loss"].includes(status) ? this.remainingBalance : monthly_payment;
    }
  }
}
</script>

<style>

</style>
